import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import Overview from "@/views/Overview.vue";
import EditPerson from "@/views/EditPerson.vue";
import ViewPerson from "@/views/ViewPerson.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/overview",
    name: "overview",
    component: Overview,
  },
  {
    path: "/edit/:uuid",
    name: "edit",
    component: EditPerson,
  },
  {
    path: "/view/:uuid",
    name: "view",
    component: ViewPerson,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
