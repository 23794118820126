import { createStore } from "vuex";

export default createStore({
  state: {
    currentLocale: "en",
  },
  getters: {},
  mutations: {
    switchLocale(state, locale) {
      state.currentLocale = locale;
    },
  },
  actions: {},
  modules: {},
});
