<template>
  <div>
    <label
      :for="name"
      :class="[required ? 'required' : '', 'block text-sm font-medium text-gray-700']"
      v-text="label"
    />
    <div class="relative mt-1 rounded-md">
      <input
        ref="inputRef"
        :class="[
          errors.length ? errorClass : '',
          'rounded-md',
          'block w-full pr-10 sm:text-sm disabled:opacity-50 focus:outline-none focus:border-indigo-600 focus:border-2 focus:border-solid focus:ring-1 focus:ring-indigo-600'
        ]"
        aria-invalid="true"
        aria-describedby="email-error"
        :placeholder="placeholder"
        type="file"
        :name="name"
        :disabled="disabled"
        :multiple="multiple"
        @change="selectFile"
      >
      <div
        v-show="errors.length"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <ExclamationCircleIcon
          class="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      </div>
    </div>
    <p
      v-show="errors.length"
      class="mt-2 text-sm text-red-600"
    >
      {{ errors[0]?.$message }}
    </p>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'

const errorClass = ref('border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500');

const inputRef = ref(null);
const files = ref([]);
const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  name: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Object,
    default: null
  },
  errors: {
    default: () => ([]),
    type: Array
  },
});

const selectFile = (event) => {
  files.value = Array.from(event.target.files).map(f => getFile(f));
  emits('update:modelValue', files.value);
};

const removeFile = (index) => {
  inputRef.value.files.splice(index, 1)
};

const getFile = (file) => {
  const fileName = file.name;
  const fileExtension = fileName.substr(fileName.lastIndexOf(".") + 1);
  const fileMimeType = file.type;
  const reader = new FileReader();
  const fileData = ref(null);
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    fileData.value = reader.result.split('base64,')[1];
  };

  return { fileName, fileExtension, fileMimeType, fileData };
}

</script>