<template>
  <div>
    <FormBuilder
      v-if="formConfig"
      ref="formBuilder"
      :form-config="formConfig"
      :data="data"
      :scoped="scoped"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import FormBuilder from '@/components/FormBuilder.vue';

const props = defineProps({
  formConfigFileName: {
    type: String,
    required: true
  },
  data: {
    type: Object,
    default: null
  },
  scoped: {
    type: Boolean,
    default: false
  }
});

const formConfig = ref(null);

import(`@/assets/form-config/${props.formConfigFileName}.json`)
  .then(data => {
    formConfig.value = data.default;
  })
  .catch(err => {
    console.log(err);
  })

const formBuilder = ref(null);

const getData = () => formBuilder.value?.getData();

const isValid = () => formBuilder.value?.isValid();

const touch = () => formBuilder.value?.touch();

defineExpose({ getData, isValid, touch });



</script>

<style>
.required::after {
  content: "*";
}
</style>