import * as validators from "@vuelidate/validators";
import i18n from "@/config/i18n/i18n";

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const { t } = i18n.global || i18n;

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({ t });

// wrap each validator.
export const required = withI18nMessage(validators.required);
export const requiredIf = withI18nMessage(
  (field) => (value, siblings) => {
    if (
      Array.isArray(field.dependsOnValue) &&
      field.dependsOnValue.includes(siblings[field.dependsOnField])
    ) {
      return validators.helpers.req(value);
    } else if (siblings[field.dependsOnField] === field.dependsOnValue) {
      return validators.helpers.req(value);
    } else if (field.dependsOnValue === "any") {
      return validators.helpers.req(value);
    }
    return true;
  },
  { withArguments: true }
);
export const email = withI18nMessage(validators.email);
export const alpha = withI18nMessage(validators.alpha);
export const regexAlpha = withI18nMessage(
  validators.helpers.regex(/^[a-zA-ZÀ-ÖØ-öø-ÿ- ()]+$/)
);
export const numeric = withI18nMessage(validators.numeric);
export const regexStreetNumber = withI18nMessage(
  validators.helpers.regex(/^\d[-\dA-Za-z]*$/)
);
export const regexPostalCode = withI18nMessage(
  validators.helpers.regex(/^\d{5}$/)
);

export const login = withI18nMessage(
  validators.helpers.regex(/^[a-z][a-z0-9]*(?:[_.-]?[a-z0-9])*$/)
);
export const sameAs = withI18nMessage(validators.sameAs, {
  withArguments: true,
});
export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true,
});
export const maxLength = withI18nMessage(validators.maxLength, {
  withArguments: true,
});
