import router from "@/router/index.js";

const TIMEOUT = 1000000;

const bearerInterceptor = (config) => {
  const token = localStorage.getItem("token");
  if (token) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.timeout = TIMEOUT;
  // config.url = `${SERVER_API_URL}${config.url}`;
  return config;
};

const unAuthenticated = (error) => {
  const url = error.response?.config?.url;
  const status = error.status || error.response.status;
  if (status === 401 || status === 403) {
    router.push({ name: "home" });
    return;
  }

  return Promise.reject(error);
};

const authenticated = (response) => response;

export { bearerInterceptor, unAuthenticated, authenticated };
