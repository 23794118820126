<template>
  <Listbox
    v-if="items?.length"
    v-model="selected"
    as="div"
    :disabled="disabled"
  >
    <ListboxLabel :class="[required ? 'required' : '', 'block text-sm font-medium text-gray-700']">
      {{ label }}
    </ListboxLabel>
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-main-500 focus:outline-none focus:ring-1 focus:ring-main-500 sm:text-sm"
      >
        <span class="block truncate">
          {{ getBoxLabelText(selected) }}
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon 
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="(item, index) of items"
            :key="index"
            v-slot="{ active, modelValue }"
            as="template"
            :value="item"
            @click="$emit('update:modelValue', item); selected = item;"
          >
            <li
              :class="[active ? 'text-white bg-gray-500' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-8 pr-4']"
            >
              <span :class="[modelValue ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ getLabelText(item) }}
              </span>

              <span 
                v-if="modelValue"
                :class="[active ? 'text-white' : 'text-gray-500', 'absolute inset-y-0 left-0 flex items-center pl-1.5']"
              >
                <CheckIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>

import { ref, computed } from 'vue';

import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: null
  },
  label: {
    type: String,
    default: null
  },
  boxLabel: {
    type: String,
    default: null
  },
  items: {
    type: Array,
    default: () => []
  },
  translationKey: {
    type: String,
    default: null
  },
  disabled: Boolean,
  required: {
    type: Boolean,
    default: false
  }
});

const getLabelText = (item) => {
  if (typeof item === 'object') {
    return item.name;
  }
  return item;
};

const getBoxLabelText = (item) => {
  if (!item) {
    return props.boxLabel;
  }
  if (typeof item === 'object') {
    return item.name;
  }
  return item;
};

const selected = ref(props.modelValue || null);

defineEmits(['update:modelValue']);

</script>