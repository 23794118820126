<template>
  <div>
    <label
      :for="name"
      :class="[required ? 'required' : '', 'block text-sm font-medium text-gray-700']"
      v-text="label"
    />
    <div 
      :class="[type === 'file' ? '' : 'shadow-sm' ,'relative mt-1 rounded-md']"
    >
      <input
        :class="[
          errors.length ? errorClass : '',
          'rounded-md',
          'block w-full pr-10 sm:text-sm disabled:opacity-50 focus:outline-none focus:border-indigo-600 focus:border-2 focus:border-solid focus:ring-1 focus:ring-indigo-600'
        ]"
        aria-invalid="true"
        :placeholder="placeholder"
        :type="type"
        :value="modelValue"
        :name="name"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <div
        v-show="errors.length"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <ExclamationCircleIcon
          class="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      </div>
    </div>
    <p
      v-show="errors.length"
      class="mt-2 text-sm text-red-600"
    >
      {{ errors[0]?.$message }}
    </p>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'

const errorClass = ref('border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500');

const props = defineProps({
  name: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  modelValue: {
    type: [String, Number],
    default: null
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  errors: {
    default: () => ([]),
    type: Array
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(['update:modelValue']);

</script>