import { createI18n } from "vue-i18n";

import en from "./en.json";
import fr from "./fr.json";

const numberFormats = {
  fr: {
    currency: {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    price: {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    price: {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};

const datetimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    medium: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    medium: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
  },
};

const i18n = createI18n({
  silentTranslationWarn: false,
  warnHtmlMessage: false,
  fallbackLocale: "en",
  globalInjection: true,
  legacy: false,
  numberFormats,
  datetimeFormats,
  locale: "en",
  messages: { en, fr },
});

export default i18n;
