<template>
  <div class="w-1/6 mx-auto my-12">
    <form class="space-y-6" action="#" method="POST">
      <div>
        <label for="user" class="block text-sm font-medium text-gray-700" v-text="'Username'" />
        <div class="mt-1">
          <input id="user" name="user" type="text" autocomplete="user" required=""
            class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            v-model="user" />
        </div>
      </div>
  
      <div>
        <label for="password" class="block text-sm font-medium text-gray-700" v-text="'Password'" />
        <div class="mt-1">
          <input id="password" name="password" type="password" autocomplete="current-password" required=""
            class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            v-model="password" />
        </div>
      </div>

  
      <div class="flex flex-col gap-y-1">
        <Button :text="'Login'" @click.prevent="doLogin()" />
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'

import Button from '@/components/ButtonVue.vue';

import api from '@/services/api.service';

const store = useStore();
const router = useRouter();

const user = ref(null);
const password = ref(null);


const doLogin = () => {
  const data = { user: user.value, password: password.value };
  api
    .post('api/authenticate', data)
    .then(result => {
      localStorage.setItem('token', result.data.accessToken)

      router.push({ name: 'overview' });
    })
    .catch((err) => {
      console.log(err)
    });
};

</script>
