<template>
  <div class="w-1/2 mx-8 my-8 flex flex-col space-y-8">
    <FormCreator
      ref="form"
      :key="data?.email"
      form-config-file-name="moulay"
      class="mt-6"
      :data="data"
    />

    <button 
      type="button"
      class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      @click="save()"
    >
      Save data
    </button>

    <br>
    <br>
    <img :src="passportData">
  </div>  
</template>

<script setup>
import { ref, watch } from 'vue';
import Button from '@/components/ButtonVue.vue';
import FormCreator from '@/components/FormCreator.vue'
import BaseService from '@/services/base.service';

import { useRoute } from 'vue-router';

const form = ref(null);
const route = useRoute();
const data = ref(null);
const passportData = ref(null);

const init = () => {
  BaseService
    .get(`/api/registrations/${route.params.uuid}`)
    .then(response => {
      data.value = response.data;
    });

  BaseService
    .get(`/api/registrations/${route.params.uuid}/identity`)
    .then(response => {
      passportData.value = response.data;
    });
};

const save = () => {
  const updatedData = Object.assign(data.value, form.value.getData())
  BaseService
    .put(`/api/registrations/${data.value.uuid}`, updatedData)
    .then(() => {
      alert('Successfully saved data.')
    })
    .catch(e => console.log(e));
};

init();

watch(() => route.params, () => {
  init();
})

</script>