<template>
  <div 
    v-if="data"
    class="w-1/2 mx-8 my-8"
  >
    <div>Name: {{ data.firstName }} {{ data.name }}</div>
    <div>Gender: {{ data.gender }}</div>
    <div>Date of birth: {{ data.birthDate ? $d(new Date(data.birthDate), 'short', 'en') : '' }} {{ data.ageClass }}</div>
    <div>Address: {{ `${data.street} ${data.streetNumber}, ${data.postalCode} ${data.city}, ${data.country}` }}</div>
    <div>E-Mail: {{ data.email }}</div>
    <div>Mobile: {{ data.mobile }}</div>
    <div>T-Shirt size: {{ data.lycraSize }}</div>
    <div>Morocco citizen: {{ data.marokkoCitizen ? 'Yes' : 'No' }}</div>
    <div>Categories: {{ data.fullCategories ? data.fullCategories : 'Not yet selected' }}</div>
    <div>Privacy agreement: {{ data.privacyAgreement ? 'Yes' : 'No' }}</div>
    <div v-if="data.ageClass === 'junior'">
      <div>Parent agreement: {{ data.parentAgreement ? 'Yes' : 'No' }}</div>
      <div>Parents name: {{ data.authorizedParentFirstname }} {{ data.authorizedParentName }}</div>
    </div>
    <div>Morocco accommodation: {{ data.marokkoAddress }}</div>
    <div>Passport / ID card number: {{ data.passportNumber }}</div>
    <br>
    <br>
    <img :src="passportData">
  </div>  
</template>

<script setup>
import { ref, watch } from 'vue';
import Button from '@/components/ButtonVue.vue';
import FormCreator from '@/components/FormCreator.vue'
import BaseService from '@/services/base.service';

import { useRoute } from 'vue-router';

const route = useRoute();
const data = ref(null);
const passportData = ref(null);

const init = () => {
  BaseService
    .get(`/api/registrations/${route.params.uuid}`)
    .then(response => {
      data.value = response.data;
    });

    BaseService
    .get(`/api/registrations/${route.params.uuid}/identity`)
    .then(response => {
      passportData.value = response.data;
    });
};

init();

watch(() => route.params, () => {
  init();
})

</script>