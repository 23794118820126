<template>
  <div class="w-full px-8 my-8">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Registrations</h1>
          <p class="mt-2 text-sm text-gray-700">A list of all the registrations for Moulay.</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-col space-y-2">
          <button 
            type="button"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="sendPrivacyMail()"
          >
            Send Privacy Mails
          </button>

          <button 
            type="button"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="resendPrivacyMail()"
          >
            Resend Privacy Mails
          </button>

          <button 
            type="button"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="convertPDFs()"
          >
            Convert PDF2Image
          </button>

          <button 
            type="button"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="createPDFFromProfiles()"
          >
            Create PDF Profiles
          </button>

          <button 
            type="button"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="createPDFList()"
          >
            Get PDF List
          </button>

          <!-- <form :action="`${API_URL}/api/download-pdf-profiles`" method="get">
            <button 
              type="button"
              class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="downloadPDFProfiles()"
            >
              Download PDF Profiles
            </button>
          </form> -->

          <!-- <button 
            type="button"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="downloadPDFProfiles()"
          >
            Download PDF Profiles
          </button> -->
        </div>
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Contact</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Address</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Morocco Address</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Passport Number</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Category</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Gender</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">T-Shirt Size</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Age group</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Privacy</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr
                  v-for="person in people"
                  :key="person.email"
                  :class="[person.privacyAgreement ? 'bg-green-200' : 'odd:bg-white even:bg-gray-100']"
                >
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ `${person.firstName} ${person.name}` }} <br> {{ person.birthDate ? $d(new Date(person.birthDate), 'short', 'en') : '' }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.email }} <br> {{ person.mobile }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ `${person.street} ${person.streetNumber}` }} <br> {{ `${person.postalCode} ${person.city}` }} <br> {{ person.country }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.marokkoAddress ? person.marokkoAddress : '' }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.passportNumber ? person.passportNumber : '' }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div
                      v-for="(category, index) in person.fullCategories?.split(',')"
                      :key="index"
                    >
                      {{ category }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.gender === 'male' ? 'Male' : 'Female' }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.lycraSize }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.age === 'junior' ? 'Junior' : 'Senior' }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div>Privacy mail sent: {{ person.privacyMailSent ? 'Yes' : 'No' }}</div>
                    <div>Privacy mail resent: {{ person.privacyMailResent }}</div>
                    <div>Privacy agreement: {{ person.privacyAgreement ? 'Yes' : 'No' }}</div>
                    <div v-if="person.ageClass === 'junior'">
                      <div>Parent agreement: {{ person.parentAgreement ? 'Yes' : 'No' }}</div>
                    </div>
                  </td>
                  <td class="class flex flex-col space-y-2">
                    <!-- <div
                      href="#" 
                      class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      @click.prevent="$router.push({ name: 'edit', params: {uuid: person.uuid}})"
                    >
                      Edit
                    </div> -->
                    <div
                      href="#" 
                      class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      @click.prevent="$router.push({ name: 'view', params: {uuid: person.uuid}})"
                    >
                      View
                    </div>
                    <div
                      href="#" 
                      class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      @click.prevent="$router.push({ name: 'edit', params: {uuid: person.uuid}})"
                    >
                      Edit
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { ref } from 'vue';
import BaseService from '@/services/base.service';
import { saveAs } from 'file-saver';

const API_URL = process.env.VUE_APP_API_URL;
const people = ref(null);

BaseService
  .retrieve('/api/registrations')
  .then(response => {
    people.value = response.data.registrations;
  })


const sendPrivacyMail = () => {
  BaseService
    .post('/api/send-privacy-mail')
    .then()
    .catch(e => console.log(e));
};

const resendPrivacyMail = () => {
  BaseService
    .post('/api/resend-privacy-mail')
    .then()
    .catch(e => console.log(e));
};

const createPDFFromProfiles = () => {
  BaseService
    .post('/api/create-pdf')
    .then(() => alert('PDF generation finished'))
    .catch(e => console.log(e));
};

const convertPDFs = () => {
  BaseService
    .post('/api/convert-pdf2image')
    .then()
    .catch(e => console.log(e));
};

const createPDFList = () => {
  BaseService
    .get('/api/pdf-list', { responseType: 'application/pdf' })
    .then(res => {
      console.log(res);
      // const blob = new Blob([res.data], { type: 'application/pdf' });
      // saveAs(blob, "list.pdf");
    })
    .catch(e => console.log(e));
};


const createFile = (data, fileName, fileType) => {
  try {
    const blob = new Blob([data], { type: fileType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (err) {
    console.log(err)
  }
};

function str2bytes (str) {
    var bytes = new Uint8Array(str.length);
    for (var i=0; i<str.length; i++) {
        bytes[i] = str.charCodeAt(i);
    }
    return bytes;
}

const downloadPDFProfiles = () => {

  // saveAs(process.env.VUE_APP_API_URL + '/api/download-pdf-profiles', "rider-profiles.zip");

  // BaseService
  //   .post('/api/send-pdf-profiles')
  //   .then()
  //   .catch(e => console.log(e))

  BaseService
    .get('/api/download-pdf-profiles', { responseType: 'stream' })
    .then(res => {
      console.log(res);
      const blob = new Blob([res.data], { type: 'application/zip' });
      return blob;
    })
    .then(blob => {
      saveAs(blob, 'rider-profiles.zip')
    })
    .catch(e => console.log(e));
};

</script>